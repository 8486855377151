// Global Footer
// Edit mode UI
.custom-footer-extra{
  @include edit_ui_element($padding: 10px);
  &:empty{ display: none; }
}
$custom-footer-text-color: $color-gray-dk;
$custom-footer-text-size: 16px;
$custom-footer-text-font-family: $font-secondary;
$custom-footer-text-weight: 400;

$custom-footer-header-text-color: $color-primary;
$custom-footer-header-text-size: 20px;
$custom-footer-header-text-font-family: $font-primary;
$custom-footer-header-text-weight: 500;
$custom-footer-header-text-case: capitalize;

$custom-footer-link-color: $color-gray-dk;
$custom-footer-link-color-hover:$color-gray-dk;
$custom-footer-link-size: 16px;
$custom-footer-link-font-family: $font-secondary;
$custom-footer-link-weight: 400;

$custom-footer-social-background-color: $color-secondary;
$custom-footer-social-background-color-hover: $color-primary;
$custom-footer-social-text-color: white;

.snFooterContainer{
  position: relative;
  &:before {
    content:'';
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background-image:url('../images/Footer-Image.jpg');
    background-repeat: no-repeat;
    filter: saturate(0%);
    opacity: 0.08;
    z-index: 0;
}
.custom-footer-extra {
  @include content-area-width;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  padding: 100px 20px 120px 20px;
  position: relative;
  @media screen and (max-width: $breakpoint-intermediate-max) {
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 20px;
  }
  }


  [class*="custom-footer-col-"] {
    width: 20%;
    display: flex;
    flex-flow: row wrap;
    padding: 0 5px;
    @media screen and (max-width: $breakpoint-intermediate-max) {
      width: auto;
      // flex: 1 1 200px;
      display: none;
    }
  }
  @media screen and (max-width: $breakpoint-intermediate-max) {
    .custom-footer-col-1 {
      display: flex;
      padding-bottom: 30px;
    }
  .custom-footer-col-4, .custom-footer-col-5 {
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
    align-items: center;
    .text {
      text-align: center;
      p {
        line-height: 1.875;
      }
    }
    .textBlockElement {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
  .textBlockElement > h3{
    color: $custom-footer-header-text-color;
    font-size: $custom-footer-header-text-size;
    font-family: $custom-footer-header-text-font-family;
    font-weight: $custom-footer-header-text-weight;
    text-transform: $custom-footer-header-text-case;
    margin-bottom: 1em;
  }
  .text{
    color: $custom-footer-text-color;
    font-size: $custom-footer-text-size;
    font-family: $custom-footer-text-font-family;
    font-weight: $custom-footer-text-weight;
    ul{
      list-style: none;
      margin: 0;
      li{
        margin-bottom: 1em;
      }
    }
    h1, h2, h3, h4, h5, h6{
      color: $custom-footer-header-text-color;
    }
    p {
      margin: 0;
    }
    a{
      color: $custom-footer-link-color;
      font-size: $custom-footer-link-size;
      font-family: $custom-footer-link-font-family;
      font-weight: $custom-footer-link-weight;
      &:hover{
        color: $custom-footer-link-color-hover;
        text-decoration: underline;
      }
    }
  }
  .heroPhotoElement {
    max-width: 150px;
    height: auto;
  }
  .sn-social-media-list{
    display: flex;
    justify-content: center;
    align-items: center;
    .sn-social-media-icon{
      border-radius: 0;
      height: 30px;
      width: 30px;
      margin: 0 5px;
      font-size: 19px;
      opacity: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $color-secondary!important;
      transition: background $transition-linear, color $transition-linear;
      color: white!important;
      &:last-of-type {
        margin-right: 0;
      }
      &:hover{
        color: white!important;
        background-color: $color-primary!important;
      }
       &:before{
        font-size: 20px;
        width: auto;
        line-height: 1;
      }
    }
  }
}