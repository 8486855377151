/* News Slideshow Styles */
// 1. Global Styling
// 2. Thumbnails
// 3. Controls
// 4. Buttons
// 5. Aspect Ratio Adjustments
// 6. Accomidations for Small Columns & Mobile Views

// Variables
$control-height: 34px;

// 1. Global Styling
.drag .newsSlideShow { cursor: pointer; }

.newsSlideShow-container {
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
  padding-bottom: $control-height;
}

.newsSlideShow-article {
  display: none;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  // height: calc(100% - #{$control-height});
  height: 100%;
  &.current {
    z-index: 1;
  }

  img { position: absolute; }
}

.newsSlideShow-headline {
  position: absolute;
  bottom: 0;
  z-index: 1;
  width: 100%;
  margin: 0;
  background-color:white;
  height: 124px;
  @media only screen and (max-width:$breakpoint-sm-max) {
    height: 100px;   
   }
  &:hover {
    h1 { 
        a {
        color: $color-secondary;
        }
    
    }
   span {
      color: $color-secondary;
    }
    .newsButton {
      background-color: $color-secondary;
      &:after {
        color: white;
      }
    }
  }

  h1 {
    position: relative;
    display: block;
    width: calc(100% - 50px);
    margin: 0;
    padding: 20px 20px 10px 20px;
    font-size: 28px;
    text-transform: none;
    font-weight: 700;
    color: $color-gray-dk;
    transition: color $transition-linear;

    &:before {
      display: none;
    }

    &:after {
      content: "";
      clear: both;
    }

    a {
      display: block;
      width: 100%;
      font-size: 28px;
      text-transform: none;
      font-weight: 700;
      color: $color-gray-dk;
      line-height: 1.2;
      text-decoration: none;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
      transition: color $transition-linear;
      @media only screen and (max-width:$breakpoint-sm-max) {
        font-size: 18px;
      }
      &:hover {
        color: $color-secondary;
      }
    }
    @media only screen and (max-width:$breakpoint-sm-max) {
      padding:10px;
    }
  }

  p {
    @extend %clearfix;
    display: block;
    width: 96%;
    padding-left: 0px;
    margin: 0;
    line-height: 1.5em;
    font-style: normal;
    font-family: $font-secondary;
    font-size: 16px;
    font-weight: 400;
    color: $color-gray-dk;
    @media only screen and (max-width:$breakpoint-sm-max) {
      font-size: 14px;
    }


    span {
      display: block;
      float: left;
      width: auto;
      max-width: calc(100% - 50px);
      background-color:transparent;
      padding-left: 20px;
      overflow: hidden;
      transition: color $transition-linear;
      @include truncate-text;
      @media only screen and (max-width:$breakpoint-sm-max) {
        padding-left:10px;
      }
      &:empty { display:none; }
    }
  }
}

// 2. Thumbnails
.newsSlideShow-thumbnails {
  position: absolute;
  z-index: 10;
  bottom: 134px;
  width: 100%;
  height: auto;
  list-style-type: none;
  line-height: 1;
  text-align: center;
  background-color:transparent;
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
    @media only screen and (max-width:$breakpoint-sm-max) {
     bottom: 100px;   
    }
  .col-md-3 & {
    font-size: .5em;
  }

  @media only screen and (max-width: 450px) {
    font-size: .5em;
  }

  li {
    display: inline-block;
  }

  a {
    display: block;
    padding: 4px;

    .col-md-3 & {
      padding: 6px;
    }

    @media only screen and (max-width: 450px) {
      padding: 6px;
    }

    &:before {
      content: '';
      display: block;
      width: .75em;
      height: .75em;
      border-radius: 100%;
      background-color: $link_color;
      transition:
        transform .1s ease-in-out,
        background-color .1s ease-in-out;
        display: none;
    }

    &:hover,
    &.current {
      img {
        border: 2px solid $color-secondary;
      }
      &:before {
        background-color: $accent_color;
        transform: scale(1.375,1.375);
        display: none;
      }
    }
  }

  img {
    width: 75px;
    height: 50px;
    object-fit: cover;
    object-position: top;
    border: 2px solid white;
    @media only screen and (max-width:$breakpoint-sm-max) {
        width: 45px;
        height: 30px;
    }
  }
}

// 3. Controls
.newsSlideShow-controls {
  display: none;
}

.newsSlideShow-prev.pill-small-left,
.newsSlideShow-next.pill-small-right {
  position: absolute;
  z-index: 10;
  bottom: 0;
  overflow: hidden;
  width: $control-height;
  height: $control-height;
  padding: 0;
  background-color: #fff;
  box-shadow: 0 -2px $borderColor;
  font-size: 1em;
  text-align: center;
  color: $link-color;

  &:hover {
    color: $accent_color;
    background-color: #fff;

    &:before {
      transform: scale(1.375);
    }
  }

  &:before {
    line-height: 2.5;
    content: '\f060'; // fa-arrow-right
    display: block;
    color: inherit;
    font-family: 'fontawesome';
    transition:
      transform .1s ease-in-out,
      color .1s ease-in-out;
  }
}

.newsSlideShow-prev.pill-small-left {
  left: 0;
}

.newsSlideShow-next.pill-small-right {
  right: 0;
}

.newsSlideShow-next.pill-small-right:before {
  content: '\f061'; // fa-arrow-left
}

.newsSlideShow-empty {
  text-align: center;

  > p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
  }
}

// 4. Buttons
.newsButton {
  background-color: $color-gray;
  width: 50px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  transition: background-color $transition-linear;

  &:after {
    content:'\f054';
    font-family: 'fontawesome';
    font-size: 14px;
    color: $color-secondary;
    transition: color $transition-linear;
  }
}
.newsSlideShow-more {
  font-size: 0;
}

.newsSlideShow-stop,
.newsSlideShow-play { display: none !important; }

// 5. Aspect Ratio Adjustments
.aspect_ratio_original,
.aspect_ratio_1_1,
.aspect_ratio_4_3,
.aspect_ratio_16_9,
.aspect_ratio_2_1,
.aspect_ratio_3_1,
.aspect_ratio_4_1,
.aspect_ratio_8_5 { height: initial !important; }

.aspect_ratio_original { @include aspect-ratio(16,9); }
.aspect_ratio_1_1 { @include aspect-ratio(1,1); }
.aspect_ratio_4_3 { @include aspect-ratio(4,3); }
.aspect_ratio_16_9 { @include aspect-ratio(16,9); }
.aspect_ratio_2_1 { @include aspect-ratio(2,1); }
.aspect_ratio_3_1 { @include aspect-ratio(3,1); }
.aspect_ratio_4_1 { @include aspect-ratio(4,1); }
.aspect_ratio_8_5 { @include aspect-ratio(8,5); }

// 6. Accommodations for Small Columns & Mobile Views
// @media only screen and (min-width: 767px) {
//   .col-md-3,
//   .col-md-4 {
//     .newsSlideShow-headline {
//       h1 a {
//         font-size: rem(18);
//       }
//       p {
//         display: none;
//       }
//     }
//   }
//   .col-md-3,
//   .col-md-4,
//   .col-md-6,
//   .col-md-8 {
//     .aspect_ratio_4_1,
//     .aspect_ratio_3_1,
//     .aspect_ratio_2_1 {
//       .newsSlideShow-headline {
//         padding-bottom: 2em;
//       }
//     }
//   }
// }
// @media only screen and (max-width: 450px) {
//   .newsSlideShow-headline {
//     .aspect_ratio_4_1 &,
//     .aspect_ratio_3_1 &,
//     .aspect_ratio_2_1 & {
//         padding-bottom: 2em;
//     }
//     h1 a {
//       font-size: rem(18);
//     }
//     p {
//       display: none;
//     }
//   }
// }