.has-mobile-nav {
    body {
        display: flex;
        flex-direction: column;
    }
    .scoreboard-tab-container {
        order: -1;
    }
    #topNav {
        order:-2;
    }
}
.scoreboard-tab-container {
    background: white;
    display: flex;
    flex-direction: column;
    width: 100%;
    .tab-container {
        background-color:$color-primary;
        display: flex;
        align-items: center;
        height: 50px;
        padding: 0 calc((100vw - #{$content-area-width})/2);
        .page-manager-visible & {
            padding: 0 calc((100vw - #{$content-area-width} - 60px)/2);
        }
        .top-tabs {
            display: flex;
            justify-content: flex-start;
            flex-basis: 50%;
            height: 100%;
            list-style: none;
            li {
                color: white;
                background-color: transparent;
                height: 100%;
                display: flex;
                align-items: flex-end;
                justify-content: center;
                width: 78px;
                transition: background-color $transition-linear;
                &:after {
                    content: '';
                    width: 0; 
                    height: 0; 
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;  
                    border-top: 10px solid white;
                    display:flex;
                    position: relative;
                    z-index: -1;
                    transition: top .5s linear, z-index .5s linear;
                }
                &.current {
                    background-color: white;
                    color: black;
                    &:after {
                        z-index: 1;
                        top: 8px;
                    }
                }
            }
        }
        .logos {
            display: flex;
            justify-content: flex-end;
            flex-basis: 50%;
            align-items: center;
            a {
                max-width: 110px;
                height: auto;
                margin: 0 30px 0 0;
                &:last-of-type {
                    margin: 0 0;
                }
                img {
                    width: 100%;
                    height: auto;
                    display: flex;
                }
            }
            .has-mobile-nav & {
                display: none;
            }
        }
    }
    .top-scoreboard {
        width: 100%;
    }
}

.tab-content{
    display: none;
    background:#fff;
    height: 125px;
    .pageElement {
        margin: 0 0;
    }
}

.tab-content.current{
    display: flex;
    align-items: center;
}
.tab-link {
    background-size: auto 50%;
    background-repeat: no-repeat;
    background-position: center;
    transition: background-image $transition-linear;
}
.tab-link:nth-of-type(1) {
    background-image: url('../images/EHL-word-white.svg');
    &.current {
        background-image: url('../images/EHL-word-blue.svg');
    }
}
.tab-link:nth-of-type(2) {
    background-image: url('../images/EHLP-word-white.svg');
    &.current {
        background-image: url('../images/EHLP-word-blue.svg');
    }
}