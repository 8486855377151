#PreviewStatsDiv .next_game {
  background-color:#fff;
  color:$color-gray-dk;
  overflow:hidden;
  display:flex;
  flex-wrap:wrap;
  justify-content: space-around;
  border: 1px solid $color-gray;
  padding: 30px;
  @media screen and (max-width: $breakpoint-sm-max){
    flex-direction: column;
  }
}
#RosterPlayerStats {
  position: relative;
  padding-left: 10px;
  padding-right: 10px;

  .next_game_splits {
    border-bottom:10px solid #333;
    margin-top:0;
    padding:0;
    .NginTableControls {
      background-color:#333;
      color:#FFF;
    }
  }
  .next_game{
    .info {
      padding-top:6px;
      text-align:center;
      line-height:16px;
      flex: 1 1 auto;
    }
    .header {
      display:block;
      font-size:18px;
      margin-bottom:2px;
    }
    .vs_or_at {
      font-size:36px;
      display:block;
      margin-bottom:20px;
      display: block;
      font-weight: 900;
    }
    .time {
      display:block;
      font-size:12px;
      color:#999;
    }
    .team {
      flex: 1 1 auto;
      .team_name {
        font-family: $font-primary;
        font-weight: 600;
        color: $color-gray-dk;
        font-size: 14px;
      }
    }
    .away_team {
      text-align:left;
      @media screen and (max-width: $breakpoint-sm-max){
        text-align: center;
      }
    }
    .home_team {
      text-align:right;
      @media screen and (max-width: $breakpoint-sm-max){
        text-align: center;
      }
    }
    .team_logo {
      @media screen and (max-width: $breakpoint-sm-max){
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .teamLogo-extra_large  {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        height: 100px!important;
      }
    }
  }
  .team_logo {
    display:inline-block;
  }
  .team_name {
    font-size:18px;
    line-height:20px;
    height:20px;
    margin:4px 0 0;
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
  }
  .stat-category-header {
    @include special-header;
    margin-top: 15px;
  }
  .statTable {
    @include line-pattern-border($pos: bottom);

    .last {
      td {
        font-weight: bold;
        background-color: transparent;
      }
    }
  }
  .goToLink {
    @include link-with-arrow;
    display: inline-block;
    margin-bottom: 15px;
    padding-left: 0;
    color: $link-color;

    &:before {
      display: none;
    }
  }
}
#RosterPlayerStats td.date,
#RosterPlayerStats td.name,
#RosterPlayerStats td.score,
#RosterPlayerStats td.split {
  white-space:nowrap;
}
#RosterPlayerStats td:first-child,
#RosterPlayerStats th:first-child { text-align:left; padding-left:10px; }
#RosterPlayerStats tr[data-toggles] td:first-child { padding-left:1.8em; }
#RosterPlayerStats tr.totals td { background-color:#555; color:#FFF; }
#RosterPlayerStats tr.season td { background-color:#DDD; border-top:1px solid #BBB; }
#RosterPlayerStats tr.subseason td { background-color:#e8e9e9; }
#RosterPlayerStats tr.game_log + tr.subseason td { border-top:1px solid #CCC; }
#RosterPlayerStats tr.subseason_totals { background-color: #e8e9e9; }
#RosterPlayerStats tr.subseason_totals td { background-color: #e8e9e9; }



#RosterPlayerStats tr.totals td,
#RosterPlayerStats tr.season td,
#RosterPlayerStats tr.subseason td {

}
#RosterPlayerStats tr.subseason td {
  color: #676767;
  font-weight: bold;
}
#GameLogDiv{
  .result {
    width: 7%;
    min-width: 50px;
  }
  .opponent {
    width: 20%;
  }
}

#CareerStatsDiv .statTable{
  td.description {
    text-align: left;
  }
  tr.season td.date {
    padding-left: 20px;
  }
}
#RosterPlayerStats .inactive_notice {
  color: white;
  background-color: #9f171d;
  padding: 10px 0 10px 10px;
  margin-bottom: 15px;
  width: 99%;
  strong{
    text-transform: uppercase;
  }
}
i.designation {
  background-image: url(/app_images/roster_player_designations.gif);
  display: inline-block;
  &.uncommitted { background-position: 0px 0px; width: 10px; height: 10px; }
  &.suspended { background-position: -15px 0px; width: 10px; height: 10px; }
  &.rookie { background-position: -30px 0px; width: 10px; height: 10px; }
  &.pmj { background-position: -45px 0px; width: 26px; height: 10px; }
  &.injured { background-position: -76px 0px; width: 10px; height: 10px; }
  &.import { background-position: -91px 0px; width: 18px; height: 10px; }
  &.committed { background-position: -114px 0px; width: 10px; height: 10px; }
  &.affiliate { background-position: -129px 0px; width: 10px; height: 10px; }
}
.rosterTableOverflow{
  overflow-x: auto;
  overflow-y:visible;
}

.rosterListingTable{
  .photo{
    width: 3%;

    img{
      border-radius: 100%;
      border: 3px solid #ddd;
      display: block;
      height: 50px;
      width: 50px;
      margin: 0 auto;
    }
  }
  .number {
    width: 3%
  }
  tbody td.name {
    text-align: center;
    width: 20%;
  }
  tbody td {
    width: 10%
  }
}
