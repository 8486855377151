/* Custom Theme Styles */
// 1. Base Styles


// 1. Base Styles
html {
  box-sizing: border-box;
}

* {
  box-sizing: inherit;
}

html,
body {
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
}