.siteContainer > #masterScoreboard {
  background: transparent;
  max-width: 940px;
  margin: 18px auto;

  html.has-mobile-nav & { margin: 0; }
}


#masterScoreboard {
  background: #fff;
  .scoreboardRibbon {
    margin: 0px;
  }
}
.scoreboardRibbon {
  background: transparnet;
  z-index: 0;
}
.pageElement .scoreboardRibbon{
  margin: 0 0px;
}
$scoreboard-ribbon-padding: 0px;
$scoreboard-ribbon-height: 125px;
.scoreboardRibbon{
  height: $scoreboard-ribbon-height;
  position: relative;
  text-transform: capitalize;
  overflow: hidden;
  padding-top: $scoreboard-ribbon-padding;
  .next_button, .previous_button {
    width: 30px;
    height: $scoreboard-ribbon-height;
    cursor: pointer;
    z-index: 500;
    color: $color-secondary;
    display: block;
    font: .875em FontAwesome;
    line-height: $scoreboard-ribbon-height;
    position: absolute;
    text-align: center;
    background-color: $color-gray;
    &:hover {
      background:$color-gray;
    }
    &::selection {
      background: $color-gray;
    }
    &::-moz-selection {
      background: $color-gray;
    }
  }
  .next_button_disabled, .previous_button_disabled {
    cursor: default;
    &:before {
      color: #999;
    }
    &:hover {
      background: $color-gray;
    }
  }
  .previous_button {
    left: 0px;
    box-shadow: 2px 2px 4px 1px rgba(0,0,0,0.15);
    &:before {
      content: "\f053";
    }
  }
  .next_button {
    right: 2px;
    box-shadow: -2px 0 4px 1px rgba(0,0,0,0.15);
    &:before {
      content: "\f054";
    }
  }
  .container{
    height: 125px;
    overflow: hidden;
    position: absolute;
    left: 30px;
    right: 30px;
    z-index: 490;
    padding: 0px;
    width: auto;
    ul{
      width: 100000px;
      position: relative;
    }
  }
  .game{
    display: block;
    float: left;
    width: 201px;
  }
  .gameLink{
    height: 125px;
    width: 100%;
    cursor: pointer;
    margin: 0px;
    padding: 0px;
    text-decoration: none;
    cursor: pointer;
    background-color: $color-gray-lt;
  }
  a{
    > span{
      display: block;
      font-weight: 400;
      line-height: 1.5em;
      padding-left: 20px;
      padding-right: 20px;
      &:last-child {
        padding-bottom: 8px;
      }
    }
  }
  .date{
    background: transparent;
    color: $color-gray-md;
    font-family: $font-secondary;
    font-size: 12px;
    // margin-bottom: 5px;
    padding-top: 16px;
    padding-bottom: 5px;
    text-transform: capitalize;
    white-space: nowrap;
  }
  .team{
    font-size: 14px; 
    display: block; 
    clear: both;
    font-family: $font-primary;
    color: $color-gray-dk;
  }
  .game{
    padding-right: 2px;
    &:last-child {
      padding-right: 0;
    }
    .name{
      float: left; 
      clear: left; 
      max-width: 140px; 
      white-space: nowrap; 
      word-wrap: normal; 
      overflow: hidden; 
      text-overflow: ellipsis; 
      font-weight: 400;
      padding-bottom: 5px;
    }
    .score{
      float: right;
      font-weight: 400;
      color: $color-gray-dk;
    }
    .status{
      clear: both;
      font-family: $font-secondary;
      color: $color-gray-md;
      font-size: 12px;
      font-weight: 400;
      text-transform: uppercase;
    }
    .boom{
      .name{
        font-weight: 600;
      }
      .score{
        font-weight: 600;
      }
    }
  }
  .in_progress .status{
    font-style: normal;
    font-weight: 600;
  }
  .completed .status{
    font-weight: 400;
  }
}
// Shrink ribbon buttons when within 25% column.
@media (min-width: 1000px) {
  .col-md-3 {
    .scoreboardRibbon {
      .container {
        left: 25px;
        right: 25px;
      }
      .previous_button,
      .next_button {
        width: 18px;
      }
      .previous_button {
        left: 4px;
      }
      .next_button {
        right: 4px;
      }
    }
  }
}
