#splash{
    &.user_mode {
        #topNav, #topNavPlaceholder,
        #displayBodyHeader, .snFooterContainer, .custom-footer-sponsor, .scoreboard-tab-container {
            display: none!important;
        }
        #panelTwo {
            padding-top:0!important;
            padding-bottom:0!important;
            margin-top: 0!important;
            margin-bottom: 0!important;
        }
        // #accountNav {
        //     top: 0!important;
        // }
        .siteContainer {
            background:$color-primary;
        }
        .heroPhotoElement {
            // margin-top: 40px;
            height: 100vh;
            width: 100vw;
            left: 50%;
            position: fixed;
            transform: translateX(-50%);
            background:$color-primary;
            margin: 0;
            @media only screen and (max-width:$breakpoint-sm-max) {
                top: 25%;
            }
        }
    }
}