[id^="widget_standings_widget_"],
#tab_division_standings_content {
  .sportTableLabel {
    @include sport-table-label;
    color: $color-gray-dk;
    font-size: 24px;
    &:after {
      display: none;
    }

  }
  ul {
    ul {
      padding-top: 2px;
      position: relative;
      &:before {
        background: $bodyCopyColorThree;
        content: '';
        height: calc(100% - 4px);
        left: 2px;
        position: absolute;
        width: 3px;
        display: none;
      }
      h4.teamName:before {
        background: $bodyCopyColorThree;
        content: '';
        height: 3px;
        left: -22px;
        margin-top: -1px;
        position: absolute;
        top: 50%;
        width: 20px;
        display: none;
      }
      @media (max-width: 400px) {
        //padding-left: 4px;
        h4.teamName:before {
          display: none;
        }
      }
    }
  }
  th:not(.name) {
    text-align: center;
  }
}

tr[id^="standing_"] {
  td { white-space: nowrap; }
  td.name {
    text-align: left;
    //width: 100%;
    min-width: 300px;
    a:not(.teamName) {
      border: 1px solid $color-gray;
    }
    &.expandedView {
      //display: flex;
      //align-items: center;
      padding-left: 20px;
    }
    .teamName {
      color: $color-gray-dk;
      font-family: $font-secondary;
      font-weight: 500;
      font-size: 16px;
      transition: color $transition-linear;
      &:hover {
        color: $color-secondary;
      }
    }
  }
}

tr[id^="standing_"] .teamLogo-tiny {
  background: #fff;
  margin-right: 10px;
  position: relative;
  &:after {
    background: $accent_color;
    content: '';
    height: 100%;
    position: absolute;
    right: -5px;
    width: 3px;
  }
}
