// Aggregator News
$agg-news-element-entry-title-font: $font-primary;
$agg-news-element-entry-title-size: 24px;
$agg-news-element-entry-title-weight: 700;
$agg-news-element-entry-title-line-height: 1.2;
$agg-news-element-entry-title-color: $color-gray-dk;
$agg-news-element-entry-title-color-hover: $color-secondary;
$agg-news-element-entry-title-case: null;
$agg-news-element-entry-meta-font: $font-secondary;
$agg-news-element-entry-meta-size: 12px;
$agg-news-element-entry-meta-weight: 400;
$agg-news-element-entry-meta-line-height: 1.25;
$agg-news-element-entry-meta-color: $color-gray-md;
$agg-news-element-entry-meta-case: null;
$agg-news-element-entry-meta-padding: 5px 0px 0px 0px;
$agg-news-element-entry-body-font: $font-primary;
$agg-news-element-entry-body-size: 14px;
$agg-news-element-entry-body-weight:500;
$agg-news-element-entry-body-line-height: 1.5;
$agg-news-element-entry-body-color: $color-gray-dk;
$agg-news-element-entry-body-case: null;
$agg-news-element-entry-body-padding: 5px 0px 0px 0px;
$agg-news-element-entry-preview-image-width: 150px;
$agg-news-element-entry-preview-image-aspect-ratio: aspect-to-percent(3, 2);
$agg-news-element-entry-preview-image-margin: 0 10px 10px 0;
$agg-news-element-entry-preview-image-float: true;
$agg-news-element-entry-button-padding: 10px 0px 0px 0px;
$agg-news-element-entry-padding-y: 20px;
// RESETS
.newsAggregatorElement {
  .condensed ul {
    list-style: none;
    li:empty {
      display: none;
    }
  }
}

// List Styles
.pageEl:not(.custom-news-list) {
  .newsAggregatorElement {
    background-color: white;
    border: 1px solid $color-gray;
    // border-top: 0;
    .aggHeader {
      box-shadow: 1px 0px white, -1px 0 white;
      border-bottom: 1px solid $color-gray;
      padding-left: 20px!important;

    }
    .condensed,
    .expanded {
      padding: $agg-news-element-entry-padding-y 0;
      margin: $agg-news-element-entry-padding-y;
      border-top: 1px solid $color-gray;
    }
    .condensed:first-of-type {
      border-top: 0;
      padding-top: 30px;
    }
    .expanded:first-of-type {
      border-top: 0;
      padding-top: 30px;
    }
    .aggHeader+.condensed,
    .aggHeader+.expanded {
      margin-top: 15px; // border-top: $global-ui-border-size solid $global-ui-border-color;
    }
  }
}

.newsAggregatorElement {
  // ENTRY PREVIEW IMAGE
  .condensed>a {
    @include clearfix;
    display: block;
    position: relative;
    width: 100%;
    max-width: $agg-news-element-entry-preview-image-width;
    margin: $agg-news-element-entry-preview-image-margin;
    @if $agg-news-element-entry-preview-image-float==true {
      float: left;
    }
    &:before {
      content: "";
      float: left;
      padding-bottom: $agg-news-element-entry-preview-image-aspect-ratio;
    }
    img {
      position: absolute;
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
      object-position: center;
    }
  } // ENTRY TITLE
  .condensed .details h4 a {
    font-family: $agg-news-element-entry-title-font;
    font-size: $agg-news-element-entry-title-size;
    font-weight: $agg-news-element-entry-title-weight;
    line-height: $agg-news-element-entry-title-line-height;
    text-transform: $agg-news-element-entry-title-case;
    color: $agg-news-element-entry-title-color;
    &:hover {
      color: $agg-news-element-entry-title-color-hover;
    }
  } // ENTRY META
  .condensed .dateAuthor {
    line-height: $agg-news-element-entry-meta-line-height;
    padding: $agg-news-element-entry-meta-padding;
  }
  .condensed .newsAuthor,
  .condensed .newsDate {
    font-family: $agg-news-element-entry-meta-font;
    font-size: $agg-news-element-entry-meta-size;
    font-weight: $agg-news-element-entry-meta-weight;
    line-height: $agg-news-element-entry-meta-line-height;
    text-transform: $agg-news-element-entry-meta-case;
    color: $agg-news-element-entry-meta-color;
  } // ENTRY BODY (teaser)
  .condensed .teaser {
    font-family: $agg-news-element-entry-body-font;
    font-size: $agg-news-element-entry-body-size;
    font-weight: $agg-news-element-entry-body-weight;
    line-height: $agg-news-element-entry-body-line-height;
    text-transform: $agg-news-element-entry-body-case;
    color: $agg-news-element-entry-body-color;
    padding: $agg-news-element-entry-body-padding;
    display: none;
  } // ENTRY BUTTON
  .condensed .commentsAndMore {
    padding: $agg-news-element-entry-button-padding;
    .button-content {
      display: inline-block;
    }
    .button-commentCount {
      display: inline-block;
      padding: 0;
    }
    .button-image-right {
      display: none;
    }
  }
  .condensed .readMore .button-small {
    @include global-link-style;
    @include global-link-container;
    @include global-link-text($align: center);
    @include global-link-icon-character;
    @include global-link-icon-style;
    display: inline-block;
    padding: 10px 15px;
  }
}

// EXPANDED
.newsAggregatorElement {
  // ENTRY PREVIEW IMAGE
  .expanded .newsItemHeader>a {
    @include clearfix;
    display: block;
    position: relative;
    width: 100%;
    max-width: $agg-news-element-entry-preview-image-width;
    margin: $agg-news-element-entry-preview-image-margin;
    @if $agg-news-element-entry-preview-image-float==true {
      float: left;
    }
    &:before {
      content: "";
      float: left;
      padding-bottom: $agg-news-element-entry-preview-image-aspect-ratio;
    }
    img {
      position: absolute;
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
      object-position: center;
    }
  } // ENTRY TITLE
  .expanded .newsItemHeader h2 a {
    display: block;
    font-family: $agg-news-element-entry-title-font;
    font-size: $agg-news-element-entry-title-size;
    font-weight: $agg-news-element-entry-title-weight;
    line-height: $agg-news-element-entry-title-line-height;
    text-transform: $agg-news-element-entry-title-case;
    color: $agg-news-element-entry-title-color;
    &:hover {
      color: $agg-news-element-entry-title-color-hover;
    }
  } // ENTRY META
  .expanded .dateAuthor {
    line-height: $agg-news-element-entry-meta-line-height;
    padding: $agg-news-element-entry-meta-padding;
  }
  .expanded .newsAuthor,
  .expanded .newsDate {
    font-family: $agg-news-element-entry-meta-font;
    font-size: $agg-news-element-entry-meta-size;
    font-weight: $agg-news-element-entry-meta-weight;
    line-height: $agg-news-element-entry-meta-line-height;
    text-transform: $agg-news-element-entry-meta-case;
    color: $agg-news-element-entry-meta-color;
  } // ENTRY BODY (teaser)
  .expanded .newsItemElement>h4 {
    font-family: $agg-news-element-entry-body-font;
    font-size: $agg-news-element-entry-body-size;
    font-weight: $agg-news-element-entry-body-weight;
    line-height: $agg-news-element-entry-body-line-height;
    text-transform: $agg-news-element-entry-body-case;
    color: $agg-news-element-entry-body-color;
    padding: $agg-news-element-entry-body-padding;
  } // ENTRY BUTTON
  .expanded .commentsAndMore {
    margin: 0;
    .commentCount {
      padding: $agg-news-element-entry-button-padding;
      display: inline-block;
    }
    .button-content {
      display: inline-block;
    }
    .button-commentCount {
      display: inline-block;
      padding: 0;
    }
    .button-image-right {
      display: none;
    }
  }
  .expanded .readMore .button-small {
    @include global-link-style;
    @include global-link-container;
    @include global-link-text($align: center);
    @include global-link-icon-character;
    @include global-link-icon-style;
    display: inline-block;
  }
}

.custom-news-list {
  .newsAggregatorElement {
    background-color: white;
    // box-shadow: 0px 2px 4px rgba(0, 0, 0, .5);
    border-right: 1px solid $color-gray;
    border-left: 1px solid $color-gray;
    .aggHeader {
      background-color: $color-secondary;
      padding: 8px 0px 8px 30px;
      box-shadow: 1px 0px 0px $color-secondary, -1px 0px 0px $color-secondary;
      padding-left: 20px!important;
      span {
        color: white;
      }
      &:after {
        background-color: white!important;
      }
    }
    .condensed,
    .expanded {
      margin: 0px 20px;
      // border-top: 1px solid $color-gray;
      box-shadow: 0 -1px 0 $color-gray;
      a {
        display: none;
        &:before {
          display: none;
        }
        img {
          display: none;
        }
      }
      ul {
        li {
          h4 {
            display: flex;
            align-items: center;
            padding: 15px 0;
            a {
              display: block;
              font-size: $agg-news-element-entry-body-size;
              font-weight: $agg-news-element-entry-body-weight;
              font-family: $font-primary;
              padding-left: 10px;
              max-width: 95%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            &:before {
              content: '';
              height: 1px;
              width: 10px;
              background-color: $color-secondary;
            }
          }
          &.dateAuthor,
          &.teaser {
            display: none;
          }
          &.commentsAndMore {
            padding: 0;
          }
        }
        .readMore .button-small {
          display: none;
        }
      }
    }
    .condensed:first-of-type {
      box-shadow: none;
    }
    .expanded:first-of-type {
      box-shadow: none;
    }
  }
}







