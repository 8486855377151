.custom-slider-sponsors {
    img {
      width: auto;
      height: auto;
      max-width: 200px;
    }
    .edit_mode & {
        height: 100%;
    }
  
    .edit_mode & {
      .heroPhotoElement {
        img {
          max-height: 100px;
          width: auto;
        }
      }
    }
  } 


  .custom-footer-sponsor {
      // padding: $sponsor-slider-direction-padding-y 0;
      overflow: hidden;
      display: flex;
      height: 150px;
      background-color: #fff;
      border-top: 1px solid $color-gray-lt;
  }
  .slick-track {
    display: flex;
    align-items: center;
    height: 100%;
  }
  
   .slick-slide {
      display: flex!important;
      justify-content: center;
      align-items: center;
      border-right: 1px solid $color-gray;
      img {
        height: auto;
        width: auto;
        max-width: 200px;
        padding: 0px 10px;
        transition: scale $transition-linear;
        &:hover {
          transform: scale(1.1);
        }
          @media only screen and (max-width:$breakpoint-sm-max) {
          padding: 0 100px;
          }
          @media only screen and (max-width:$breakpoint-intermediate-max) {
              padding: 0 20px;
          }
          @media only screen and (max-width:$breakpoint-intermediate-min) {
              padding: 0 15px;
          }
      
      }
  }
  .slick-next,
  .slick-prev {
    width: 30px;
    height: 100%;
    background-color:$color-gray-lt!important;
    z-index: 100;
    &:before{
      font-size: 30px;
      color: $color-secondary;
      font-family: FontAwesome;
    }
  }
  .slick-next {
    box-shadow: -2px 0 4px 1px rgba(black, .15);
    &:before {
      content: '\f105';
    }
}

.slick-prev {
    box-shadow: 2px 2px 4px 1px rgba(black, .15);
    &:before {
      content: '\f104';
    }
}