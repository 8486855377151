.center-title {
    flex-basis: 100%;
    @media only screen and (max-width:$breakpoint-intermediate-max) {
        flex-basis: 0;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
    }
    h3 {
        @media only screen and (max-width:$breakpoint-intermediate-max) {
           padding: 20px 0!important;
        }
        justify-content: center;
        &:before {
            content: '';
            display: flex;
            height: 1px;
            flex: 1 1;
            margin: 0 20px 0 0;
            background-color: $color-secondary;
        }
        &:after {
            margin: 0 0 0 20px!important;
        }
    }
    &.white {
        h3 {
            color: white!important;
        }
    }
}
.customTagline {
    .has-mobile-nav & {
        display: none;
    }
    p {
        strong {
            color: $color-secondary;
            font-weight: 700;
        }
        color: $nav-title-bar-text-color;
        font-family: $nav-title-bar-font-family;
        font-size: $nav-title-bar-font-size;
        text-transform: $nav-title-bar-text-case;
        font-weight: $nav-title-bar-text-weight;
        display: inline-block;
        line-height: $nav-title-bar-line-height;
    }
}
.background-lt-gray {
    background-color: $color-gray-lt;
    padding-top: 50px;
    padding-bottom: 50px;
}
.background-white {
    background-color: #fff;
}
 .has-main-nav {
    .user_mode .custom-home-hero {
        .custom-news-list .newsAggregatorElement {
            margin-left: 30px;
        }
    }
}
.custom-link-container {
    .user_mode & {
        height: 0;
        margin-top: -50px;
    }
    padding: 0 calc((100vw - #{$content-area-width})/2);
    @media only screen and (max-width:$breakpoint-sm-max){
        padding-left: 0;
        padding-right: 0;
    }
    .column {
        padding-left: 5px;
        padding-right: 10px;
        @media only screen and (max-width:$breakpoint-sm-max){
            padding-left: 0;
            padding-right: 0;
        }
        &:last-of-type {
            padding-right: 0;
        }
    }
}

.custom-link {
    @media only screen and (max-width:$breakpoint-sm-max) {
        border-left: 5px solid $color-secondary;
    }
    .textBlockElement {
        box-shadow: -5px 0 0 $color-secondary, 2px 2px 10px rgba(black, .1);
        padding: 12px 0;
        margin: 0;
        background-color: white;
        position: relative;
        .text {
            p {
                width: 100%;
                padding: 0 5px;
                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: $color-gray-md;
                    font-family: $font-primary;
                    font-size: 20px;
                    font-weight: 400;
                    width: calc(100% - 30px);
                    transition: color $transition-linear;
                    text-align: center;
                    @media only screen and (max-width:$breakpoint-intermediate-max){
                        font-size: 16px;
                    }
                    @media only screen and (min-width:$breakpoint-intermediate-min) and (max-width:$breakpoint-intermediate-max) {
                        justify-content: flex-start;
                    }
                    strong {
                        font-size: 50px;
                        font-family: $font-primary;
                        color: $color-secondary;
                        font-weight: 700;
                        padding: 0 20px 0 5px;
                        display: flex;
                        justify-content: center;
                        @media only screen and (max-width:$breakpoint-intermediate-max){
                            font-size: 35px;
                            padding: 0 10px 0 5px;
                        }
                    }
                    &:after {
                        content:'\f054';
                        font-family: 'fontawesome';
                        font-size: 12px;
                        color: $color-secondary;
                        transition: transform $transition-linear;
                        position: absolute;
                        right: 20px;
                    }
                    &:hover {
                        text-decoration: none;
                        color: $color-secondary;
                        &:after {
                            transform: translateX(5px);
                        }
                    }

                }
            }
        }
    }
}

@mixin background-style {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  @mixin custom-background-edit {
    border:1px dashed #DDA301;
    .user_mode & {
        display: none;
    }
    &:after {
        content:'Set Background Image Here';
        height: 40px;
        width: 100%;
        background-color: $color-gray-lt;
        display: flex;
        align-items: center;
        justify-content: center;
        color:$color-gray-dk;
    }
  }

  .custom-background-container{
    @include background-style;
    padding-top: 50px;
    padding-bottom: 125px;

    &.league-links {
        padding-left: 0;
        padding-right: 0;
        background-color: transparent;
        position: relative;
        .column {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            padding-left: 0;
            padding-right: 0;
            @media only screen and (max-width:$breakpoint-intermediate-max) {
                flex-direction: column;
            }
        }
        &:before {
            content:'';
            display: block;
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: linear-gradient(to bottom, rgba($color-gray-lt,1) 0%,rgba(255,255,255,1) 100%);
            z-index: -1;
        }
    }
    .custom-bg {
    @include custom-background-edit;
    }
  }
  .has-main-nav {
    .add-gutters {
        .custom-call-to-action {
            margin-right: 15px!important;
                &:last-of-type {
                margin-right: 0!important;
                }
            }
    }
  }
.has-main-nav {
    .home {
        .custom-call-to-action {
            margin-right: 15px;
            &:last-of-type {
                margin-right: 0!important;
            }
        }

    }
}

        .custom-call-to-action {
            flex:1 1 0;

            @media only screen and (max-width:$breakpoint-intermediate-max) {
                padding: 0 15px;
                margin-right: 0px;
                margin-left: 0px;
                margin-bottom: 30px;
                &:last-of-type {
                    margin-right: 0px;
                    padding-right: 15px;
                }
            }
            .textBlockElement {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 0;
                box-sizing: border-box;

                .leftTextImage, .originalTextImage,
                .rightTextImage {
                    margin: 0;
                    position: relative;
                    z-index: 4;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    &:after {
                        content:'';
                        width: 100%;
                        height: 100%;
                        background-color: rgba($color-secondary, 0.8);
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 1;
                        transition: background-color $transition-linear;
                    }

                }
                .cutline-wrapper {
                    width: 100%;
                }
                .cutline {
                    z-index: 3;
                    background-color:white;
                    color:$color-gray-md;
                    font-family: $font-secondary;
                    border: 1px solid $color-gray-lt;
                    height: 50px;
                    position: relative;
                    font-style: normal;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    margin: 0;
                    padding: 0;
                    transition: color $transition-linear;
                    &:after {
                        content:'\f054';
                        font-family: 'FontAwesome';
                        font-size: 12px;
                        height: 50px;
                        width:30px;
                        background-color: transparent;
                        color: $color-secondary;
                        display: flex;
                        align-items: center;
                        justify-content:center;
                        margin-left: 15px;
                        transition: color $transition-linear, background-color $transition-linear, box-shadow $transition-linear;
                    }
                }
                .text {
                    position: absolute;
                    display: flex;
                    flex-direction: column;
                    z-index: 30;
                    color: white;
                    align-items: center;
                    box-sizing: border-box;
                    p {
                        padding: 0 30px;
                        text-align: center;
                        font-family: $font-secondary;
                        font-weight: 500;
                        font-size: 16px;
                        @media only screen and (min-width:$breakpoint-intermediate-min) and (max-width:769px) {
                            font-size: 12px;
                        }
                    }
                    p:first-of-type {
                        font-size: 36px;
                        font-weight: 800;
                        font-family: $font-primary;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        text-transform: uppercase;
                        line-height: 1.2;
                        &:after {
                            content:'';
                            width: 50px;
                            height: 1px;
                            background-color: white;
                            margin-top: 10px;
                        }
                        @media only screen and (max-width:1024px) {
                            font-size: 24px;
                        }
                        @media only screen and (min-width:$breakpoint-intermediate-min) and (max-width:769px) {
                            font-size: 18px;
                        }
                    }
                }
            }
            &:hover {
                .leftTextImage, .originalTextImage,
                .rightTextImage {
                    &:after {
                        background-color: rgba(black, 0.5);
                    }
                }
                .cutline {
                    color: $color-secondary-hover;
                    &:after {
                        background-color: $color-secondary-hover;
                        color: white;
                        box-shadow: 1px 0 0 $color-secondary-hover;
                    }
                }
            }
        }

.custom-block-container {
    .column {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        .custom-block {
            flex-basis: 50%;
            position: relative;
            @include background-style;
            border: 1px solid white;
            border-left: 0;
            &:last-of-type {
                border-right: 0;
            }
            @media only screen and (max-width:$breakpoint-intermediate-max) {
                flex-basis: 100%;
                border-bottom: 1px solid white;
            }
            &:before {
                content:'';
                height: 100%;
                width: 100%;
                background-color: $color-primary;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0.7;
            }
            &:after {
                content:'';
                height: 100%;
                width: 100%;
                background-image: linear-gradient(-90deg, rgba(10,44,116,0.00) 0%, #0A2C74 87%);
                position: absolute;
                top: 0;
                left: 0;
            }
            .leftTextImage, .rightTextImage {
                @include custom-background-edit;
                .user_mode & {
                    display: none;
                }
            }
            .pageElement {
                min-height: 400px;
                z-index: 3;
                display: flex;
                flex-direction: column;
                justify-content: center;
                position: relative;
                padding: 0 50px;
                @media only screen and (min-width:$breakpoint-intermediate-min) and (max-width:$breakpoint-intermediate-max) {
                    min-height: 500px;
                    padding: 50px 50px;
                }
                @media only screen and (max-width:$breakpoint-sm-max) {
                    min-height: 500px;
                    padding: 50px 15px;
                }

                .text {
                    p {
                        color: white;
                        text-align: left;
                        font-weight: 400;
                        font-family: $font-secondary;
                        font-size: 16px;
                        &:first-of-type {
                            @include page-element-header;
                            line-height: 1.1;
                            color: white;
                            padding: 0;
                            &:after {
                                display: none;
                            }
                        }
                        a {
                            @include global-link-container;
                            @include global-link-style;
                            @include global-link-text;
                            @include global-link-icon-style;
                            @include global-link-icon-character;
                            max-width: 150px;
                        }
                    }
                }
            }
            .block-text-wrapper {
                display: flex;
                align-items: center;
                @media only screen and (max-width:$breakpoint-intermediate-min) {
                    flex-direction: column;
                }
                .blockImg {
                    justify-content: center;
                    padding-right: 20px;
                    img {
                        max-width: 220px!important;
                        height: auto!important;
                    }
                }
                .text {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                }
            }
        }
    }
}
.has-mobile-nav {
    .custom-tabs {
        display: none;
    }
}
.custom-table-header {
    .pageElement{
        margin: 0;
        padding: 20px 0 0 85px;
        @media only screen and (max-width:$breakpoint-intermediate-min){
            padding: 20px 0 0 65px;
        }
    }
    position: relative;
    h3 {
        padding: 0!important;
        margin-bottom: 0!important;
        span {
            font-family: $font-primary;
            font-size: 24px;
            font-weight: 700;
            color: $color-gray-dk;
            text-transform: uppercase;
            line-height: 1.2;
            margin-bottom: 0;
            @media only screen and (max-width:$breakpoint-intermediate-min){
                font-size: 16px;
            }
        }
        &:after {
            display: none!important;
        }
    }
    p {
        font-family: $font-secondary;
        font-size: 16px;
        font-weight: 400;
        color: $color-gray-md;
        line-height: 1.2;
        @media only screen and (max-width:$breakpoint-intermediate-min){
            font-size: 12px;
        }
    }
    .leftTextImage,.rightTextImage {
        width: 35px!important;
        height: 35px!important;
        max-height: 35px!important;
        min-height: 35px!important;
        border: 1px solid $color-gray-md;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
        margin:20px 20px 20px 30px;
        @media only screen and (max-width:$breakpoint-intermediate-min){
            margin: 20px 15px 20px 15px;
            width: 30px!important;
            height: 30px!important;
            max-height: 30px!important;
            min-height: 30px!important;
        }
        img {
            width: 100%!important;
            height: 100%!important;
        }
    }

  }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    .custom-block-container {
        flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
    }
    .custom-block-container .column .custom-block .pageElement .text p {
        max-width: 90%;
    }
    .custom-call-to-action {
        flex: 1 1 auto;
    }
    .custom-call-to-action .textBlockElement .text {
        position: relative;
        top: -250px;
    }
    .custom-call-to-action .textBlockElement .leftTextImage::after, .custom-call-to-action .textBlockElement .originalTextImage::after, .custom-call-to-action .textBlockElement .rightTextImage::after {
        height: 82%;
    }
}

table {
    .odd {
        background-color: $color-sport-table-zebra-lt;
        td {
        background-color: $color-sport-table-zebra-lt;
        }
    }
    .even {
        background-color: $color-sport-table-zebra-dk;
        td {
        background-color: $color-sport-table-zebra-dk;
        }
    }
    &.line_score_v2.player_stats-list {
      .even {
        background-color: $color-sport-table-zebra-lt;
        td {
        background-color: $color-sport-table-zebra-lt;
        }
      }
      .odd {
        background-color: $color-sport-table-zebra-dk;
        td {
        background-color: $color-sport-table-zebra-dk;
        }
      }
    }

}

.widgetElement {
    padding-bottom: 15px;
    overflow: auto;
    .boxscore-container {
        .game-header {
            border: none;
        }
    }
    .boxscore-container,
    .game-header {
        border: 1px solid $color-gray;
        padding: 30px;
    }
}
[id*="widget_scoreboard"]{
    border: none!important;
    padding: 0;
}


#home {
    #panelTwo:before {
        background-color: $color-gray-lt;
    }
}

.home:not(.newsPage) {
    #panelTwo:before {
        background-color: $color-gray-lt;
    }
}

.custom-background-container:not(.custom-home-hero) {
    @media only screen and (max-width:$breakpoint-sm-max) {
        padding-top: 115px;
    }
    @media only screen and (min-width:$breakpoint-md-min) and (max-width:$breakpoint-intermediate-max) {
        padding-top: 180px;
    }
}

.codeElement {
    .c-button-section {
        background-color: transparent;
        &:hover, &:focus, &:active {
            background-color: transparent;
        }
    }
}

.home:not(.newsPage){
    .site-background {
        background-color: $color-gray-lt!important;
        background-image: none!important;
    }
}

.custom-home-hero {
    @media only screen and (min-width:$breakpoint-intermediate-min) {
        .col-md-8 {
            max-width: 895px!important;
        }
        .column-1 {
            padding-left: 0px!important;
        }
    }

    @media only screen and (min-width:$breakpoint-intermediate-min) and (max-width:1024px) {
        .column {
            max-width: 100%;
            width: 100%;
        }

    }
}

.user_mode {
  .remove-bg-color {
    .tabContainer .layoutContainer {
      background-color: transparent;
    }
  }
}

.player-stats {
  .tabContainer {
    .layoutContainer {
      background-color: transparent;
    }
    .widgetElement {
      border: 1px solid $color-sport-table-border;
    }
    .statTable tbody tr.highlight:nth-child(1) td{
        background-color: $color-sport-table-zebra-lt;
    }
    .statTable tbody tr.highlight:nth-child(2) td {
        background-color: $color-sport-table-zebra-dk;
    }
    .statTable tbody .highlight td {
      border-color: #e0e0e0;
    }
  }
  .widget-leader .leader-highlight {
    background-color: transparent;
    border: 1px solid $color-sport-table-border;
    border-bottom: 0;
    min-height: 126px;
  }
  @media screen and (max-width: 1279px) {
      .column {
      width: 100%;
    }
    .contentTabs:not(.rapidTabsConstruction) li {
      flex: 1 1;
      flex-basis: 100%;
      margin: 0;
      margin-bottom: 1px;
     }
     .contentTabs:not(.rapidTabsConstruction) li span {
      width: 100%;
     }
     .contentTabs:not(.rapidTabsConstruction):not(#rapidTabs) .selected:after {
       display:none;
     }
  }
}

.statTable tbody tr:nth-child(odd) td{
    background-color: $color-sport-table-zebra-lt;
}
.statTable tbody tr:nth-child(even) td{
    background-color: $color-sport-table-zebra-dk;
}

.home {
    .tabContainer {
        padding-top: 20px;
    }
}