/* Widget - Stat Leader
---------------------------------------------------------------------------------------------------- */
.widget-leader {
  .sportTableLabel {
    display: flex;
    padding: 10px 20px 0 20px;
      .teamLogo-tiny {
        width: 30px!important;
        height: 30px!important;
        border: 1px solid $color-gray;
        border-radius: 50%;
      }
        .teamName {
          display: flex;
          align-items: center;
          margin-bottom: 0;
          a.linkText {
            color: $color-gray-md;
            font-family: $font-primary;
            font-weight: 400;
            font-size: 12px;
          }
          .seasonDisplay {
            display: none;
          }
        }
    }

  .statTable {
    //width: 100%;

    th, td {
      &:nth-child(1) { text-align: center; } // rank column
      &:nth-child(2) { text-align: left; } // name column
      &:nth-child(3) { text-align: center; } // stat column
    }

    th:nth-child(1) { width: 30px; }

    td {
      white-space: nowrap;

      &:nth-child(1) { font-weight: 700; }
    }
  }

  .leaderName {
    display: inline-block;
    width: 110px;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;
    color: $color-gray-dk;
    font-family: $font-primary;
    font-weight: 500;
    font-size: 12px;
  }

  .leader-highlight {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 15px;
    background-color: $color-gray-lt;
  }

  .stats {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: 0 0 0 10px;
    text-align: center;
  }

  .stats-major,
  .stats-minor {
    margin: 0;
    padding: 0;
  }

  .player_photo {
    overflow: hidden;
    height: 70px;
    border: 1px solid transparent;
    border-radius: 50%;
    background-color: transparent;

    img {
      display: block;
      width: 70px;
    }
  }
}
.widget-multi-stat-filterable {
  .widget-leader {
    .sportTableLabel {
      padding: 20px;
    }
  }
}
