.custom-title-bar-extra {
    white-space: nowrap; // prevents items from adjusting total nav height
    order: 1;
    margin-left: auto;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    position: relative;
    .has-mobile-nav & {
        display: none;
    }
  }
  .custom-network-toggle-ehl {
      margin-right: 10px;
  }
  .custom-network-toggle-ehl,
  .custom-network-toggle-ehlp {
    cursor: pointer;
    width: 136px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-primary;
    padding: 0px 15px;
    line-height: 1.1;
    color: #FFF;
    font-style: normal;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 400;
    font-family: $font-primary;
    position: relative;
    transition: background-color $transition-linear;
    &:hover{ background-color: $color-secondary; }
    &:after{
      content: "\f078";
      font-family: FontAwesome;
      margin-left: 10px;
      font-size: 10px;
      line-height: 12px;
      transform: translateY(-.1em);
    }
  }
  
  .custom-network-menu-container-ehl{
    display: none;
    background-color:white;
    position: absolute;
    top: 100%;
    right: calc(100% - 136px);
    z-index: 999;
    border: 1px solid #EDF0F1;
    box-shadow: 2px 5px 10px 0 rgba(0,0,0,0.20);
    .custom-network-menu-ehl {
    // .custom-network-menu-ehlp{
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: flex-start;
      width: 655px;
      margin: auto;
      padding: 15px;
    }
    .network-title {
        display: block;
        width: 100%;
        font-family: $font-primary;
        font-size: 20px;
        font-weight: 700;
        color: $color-gray-dk;
        text-transform: uppercase;
        font-style: normal;
        background-color: transparent;
        margin: 0;
        padding: 10px 0;
    }
    .network-item{
      padding: 5px;
      width: 50%;
      height: 40px;
      display: flex;
      align-items: center;
      a{
        display: block;
        width: 35px;
        height: 35px;
      }
      img{
        width: 100%;
        height: 100%;
        display: block;
        object-fit: contain;
        object-position: center;
        max-width: 35px;
      }
      .cutline {
          font-family: $font-primary;
          font-size: 14px;
          font-weight: 400;
          color: $color-gray-dk;
          text-transform: capitalize;
          font-style: normal;
          background-color: transparent;
          margin: 0;
          padding: 0 15px;
          transition: color $transition-linear;
          &:hover {
            color: $color-secondary-hover;
          }
      }
    }
  }
  .custom-network-is-active-ehl{
    .custom-network-toggle-ehl {
    // .custom-network-toggle-ehlp,{
      background-color: $color-secondary;
      &:after{ content: "\f077"; }
    }
    .custom-network-menu-container-ehl{ display: block; }
  }
  .custom-progress-ehl{ display: none; }
  .custom-network-is-loading-ehl{
    .custom-progress-ehl {
      display: block;
      margin: 10px auto 0;
      width: 70px;
      text-align: center;
    }
  }
  
  .custom-network-is-unreachable{
    .custom-network-menu-ehl {
      &:after{
        content: "Content Unavailable.";
        text-align: center;
      }
    }
  }
  ///////
  .custom-network-menu-container-ehlp{
        display: none;
        background-color:white;
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 999;
        border: 1px solid #EDF0F1;
        box-shadow: 2px 5px 10px 0 rgba(0,0,0,0.20);
        .custom-network-menu-ehlp {
          display: flex;
          flex-flow: row wrap;
          justify-content: flex-start;
          align-items: flex-start;
          width: 655px;
          margin: auto;
          padding: 15px;
        }
        .network-title {
            display: block;
            width: 100%;
            font-family: $font-primary;
            font-size: 20px;
            font-weight: 700;
            color: $color-gray-dk;
            text-transform: uppercase;
            font-style: normal;
            background-color: transparent;
            margin: 0;
            padding: 10px 0;
        }
        .network-item{
          padding: 5px;
          width: 50%;
          height: 40px;
          display: flex;
          align-items: center;
          a{
            display: block;
            width: 35px;
            height: 35px;
          }
          img{
            width: 100%;
            height: 100%;
            display: block;
            object-fit: contain;
            object-position: center;
            max-width: 35px;
          }
          .cutline {
              font-family: $font-primary;
              font-size: 14px;
              font-weight: 400;
              color: $color-gray-dk;
              text-transform: capitalize;
              font-style: normal;
              background-color: transparent;
              margin: 0;
              padding: 0 15px;
              transition: color $transition-linear;
              &:hover {
                color: $color-secondary-hover;
              }
          }
        }
      }
      
      .custom-network-is-active-ehlp{
        .custom-network-toggle-ehlp {
          background-color: $color-secondary;
          &:after{ content: "\f077"; }
        }
        .custom-network-menu-container-ehlp{ display: block; }
      }
      .custom-progress-ehlp{ display: none; }
      .custom-network-is-loading-ehlp{
        .custom-progress-ehlp {
          display: block;
          margin: 10px auto 0;
          width: 70px;
          text-align: center;
        }
      }
      
      .custom-network-is-unreachable{
        .custom-network-menu-ehlp {
          &:after{
            content: "Content Unavailable.";
            text-align: center;
          }
        }
      }

      // Mobile Nav Network Menu

.has-mobile-nav{
  .theme-mobile-nav{
    .more-network-container {
      overflow-y: auto;
      position: absolute;
      top: $mobile-nav-height;
      bottom: 0;
      transition: right .2s ease;
      background: white;
      display: flex;
      flex-flow: column;
      &.dropdown-open {
        right: 0;
      }
      &.ehl{
        right: 100%;
      }
      &.ehlp{
        right: 200%;
      }
      .theme-nav-title{
        width: 100%;
        background: $color-gray-lt;
        border: 1px solid $color-gray;
        .theme-nav-title-text {
          color: $color-gray-dk;
          text-transform: uppercase;
          font-size: 14px;
        }
      }
      .network-title {
        font-family:$font-primary;
        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase;
        color: $color-gray-dk;
        padding: 30px 0px 0px 30px;
      }
    }
    .more-network-item {
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      align-items: center;
      line-height: 1.4;
      padding: 15px 15px 0 30px;
      &:hover{
        color: $color-gray-dk;
      }
    }

    .more-network-item-image {
      flex: 0 0 35px;
      width: 35px;
      display: flex;
      align-items: center;
    }

    .more-network-item-image img {
      width: 100%;
      max-width: 100%;
      max-height: 35px;
    }

    .more-network-item-title {
      padding-left: 15px;
      color: $color-gray-dk;
    }
  }
  &.custom-network-menu-mobile-active-ehl{
    .theme-mobile-nav .more-network-container{
      &.ehl { right: 0;}
    }
  }
  &.custom-network-menu-mobile-active-ehlp{
    .theme-mobile-nav .more-network-container{
      &.ehlp { right: 0;}
  
    }
  }
}
.theme-mobile-nav .mobile-nav-extra{
  > .theme-nav-link{
    color: white;
    background-color: $color-primary;
    &:hover{
      background-color: $color-primary;
    }
  }
  .theme-nav-forward {
    background-color: $color-primary;
  }
} 